/* global EM */
import _ from 'underscore';

const HelpIndex = {
    isListenerAttached: false,
    content: {
        'release': {
            key: 'release',
            excludeFromFull: true,
            cid: '604635156'
        },
        'whats-new': {
            excludeFromFull: true,
            key: 'whats-new',
            cid: '2657583109'
        },
        'overview': {
            key: 'overview',
            cid: '604864643'
        },
        'home': {
            key: 'home',
            cid: '604667925'
        },
        'dashboard': {
            key: 'dashboard',
            cid: '2432729231'
        },
        'reports/demand': {
            key: 'reports-demand',
            base: 'report-functions',
            cid: '604733597'
        },
        'reports/staffing': {
            key: 'reports-staffing',
            base: 'report-functions',
            cid: '604962823'
        },
        'visualizations/demand': {
            key: 'demand',
            cid: '604798985'
        },
        'visualizations/data-explorer': {
            key: 'dataExplorer',
            cid: '2429780146'
        },
        'visualizations/staffing/employee': {
            key: 'staffing.employee',
            cid: '604864701'
        },
        'visualizations/staffing/workitem/activity': {
            key: 'staffing.workitemActivity',
            cid: '604995585'
        },
        'visualizations/staffing/workitem/role': {
            key: 'staffing.workitemRole',
            cid: '2762342401'
        },
        'requestQueue': {
            key: 'requestQueue',
            cid: '3042017288'
        },
        'visualizations/scenarios': {
            key: 'scenarios',
            cid: '757202945'
        },
        'visualizations/scenario-view': {
            excludeFromListing: true,
            excludeFromFull: true,
            key: 'scenarios',
            cid: '757202945'
        },
        /*'management/schedules': {
            key: 'schedules',
            base: 'file-table',
            cid: '604667948'
        },*/
        'management/schedules': {
            key: 'schedules',
            cid: '756809731'
        },
        'management/headcounts': {
            key: 'headcounts',
            base: 'file-table',
            cid: '604995592'
        },
        'management/actuals': {
            key: 'actuals',
            base: 'file-table',
            cid: '756776963'
        },
        'management/allocations': {
            key: 'allocations',
            base: 'table',
            cid: '604864669'
        },
        'management/complexities': {
            key: 'complexities',
            base: 'table',
            cid: '604930081'
        },
        'management/algorithms': {
            key: 'algorithms',
            base: 'table',
            cid: '604864711'
        },
        'management/expressions': {
            key: 'expressions',
            cid: '604799001'
        },
        'management/attributes': {
            key: 'attributes',
            base: 'table',
            cid: '604930111'
        },
        'management/organizations': {
            key: 'organizations',
            base: 'table',
            cid: '604864718'
        },
        'management/departments': {
            key: 'departments',
            base: 'table',
            cid: '604799010'
        },
        'management/roles': {
            key: 'roles',
            base: 'table',
            cid: '604930138'
        },
        'management/activities': {
            key: 'activities',
            base: 'table',
            cid: '604733616'
        },
        'management/employees': {
            key: 'employees',
            base: 'table',
            cid: '604799017'
        },
        'management/assignments': {
            key: 'assignments',
            base: 'table',
            cid: '604864725'
        },
        'management/settings/general': {
            key: 'settings-general',
            cid: '604766257'
        },
        'management/settings/security': {
            key: 'settings-security',
            cid: '604864732'
        },
        'management/settings/advanced': {
            key: 'settings-advanced',
            base: 'table',
            cid: '604995625'
        },
        'management/settings/pipelines': {
            key: 'settings-pipelines',
            cid: '2430369796'
        },
        'management/settings/pipeline-view': {
            key: 'settings-pipelines',
            cid: '2430369796',
            excludeFromListing: true,
            excludeFromFull: true
        },
        'management/settings/referenceTables': {
            key: 'referenceTables',
            cid: '2433777665'
        },
        'powerbi': {
            key: 'powerbi',
            cid: '1029079041',
            excludeFromListing: true,
            excludeFromFull: true
        },
        'client': {
            key: 'client',
            excludeFromFull: true,
            cid: '605224961'
        },
        'security': {
            key: 'security',
            excludeFromFull: true,
            cid: '604799027'
        },
        'privacy': {
            key: 'privacy',
            excludeFromFull: true,
            cid: '604864739'
        },
        'base/table': {
            key: 'table',
            isBase: true,
            excludeFromFull: true,
            cid: '604766264'
        },
        'base/file-table': {
            key: 'file-table',
            isBase: true,
            excludeFromFull: true,
            cid: '604766271'
        },
        'base/report-functions': {
            key: 'report-functions',
            isBase: true,
            excludeFromFull: true,
            cid: '604799048'
        },
        'base/test': {
            key: 'test-test',
            isBase: true,
            excludeFromFull: true,
            cid: '604667990'
        },
        'function-ref': {
            key: 'function-ref',
            excludeFromListing: true,
            excludeFromFull: true,
            cid: '2487975937'
        },
        'terms': {
            key: 'terms',
            excludeFromListing: true,
            excludeFromFull: true,
            cid: '2583625729'
        },
        'test': {
            key: 'tests',
            excludeFromListing: true,
            excludeFromFull: true,
            cid: '2658992135'
        }
    },

    contentByKey: null,
    getContentMetaByKey: (key) => {
        if (!HelpIndex.contentByKey) {
            HelpIndex.contentByKey = _.indexBy(HelpIndex.content, 'key');
        }

        return HelpIndex.contentByKey[key];
    },

    getContentMeta: (pathIn) => {
        let path = pathIn || window.location.pathname.slice(1);
        let bits = path.split('/');
        let domain = EM.getActiveDomain();
        if (domain) bits = bits.slice(1);

        let pageKey = bits.join('/');
        if (!pageKey) pageKey = 'home';
        if (pageKey === '/') pageKey = 'home';

        let contentMeta = HelpIndex.content[pageKey];

        if (!contentMeta) {
            pageKey = bits.slice(0, -1).join('/');
            contentMeta = HelpIndex.content[pageKey];
        }

        return contentMeta;
    },

    loadAll: async () => {
        let values = Object.values(HelpIndex.content);
        let output = [];
        for (let i = 0; i < values.length; i++) {
            if (values[i].isBase || values[i].excludeFromFull) continue;
            let content = await HelpIndex.getContent(null, values[i]);
            output.push('<div class="section"><h1 class="section-title">' + content.title + '</h1>' + content.body + '</div>');
        };

        return { title: 'All', body: output.join(''), base: '' };
    },

    getContent: async (pathIn, meta, forExternal) => {
        let contentMeta = meta || HelpIndex.getContentMeta(pathIn);

        let base = null;
        if (contentMeta && contentMeta.base) {
            base = await HelpIndex._load(contentMeta.base);
        }

        let contentKey = contentMeta ? contentMeta.key : 'na';
        let content = await HelpIndex._load(contentKey);

        let fullContent = forExternal ? HelpIndex.composeContentForExternal(content, base) : HelpIndex.composeContent(content, base);

        return { title: EM.t(contentKey + '.title'), body: fullContent || EM.t('na.body'), contentKey: contentKey };
    },

    _load: async (contentKey) => {
        if (EM.helpCache[contentKey]) {
            return EM.helpCache[contentKey];
        } else {
            let meta = HelpIndex.getContentMetaByKey(contentKey);
            if (!meta) return;
            let response = await EM.api.loadHelp(meta.cid, contentKey, EM.currentLanguage);
            return EM.helpCache[contentKey] = response.content;
        }
    },

    composeContent: (body, base) => {
        let combined = (body || '') + (base || '');

        let listing = HelpIndex.getListing();
        let listingIndex = _.indexBy(listing, 'cid');

        let edited = combined.replace(/href="[https://intrinsiccs.atlassian.net]*\/wiki\/spaces\/RMSD\/pages\/(\d+)\/[\w+]+"/gm, (match, p1) => {
            let meta = listingIndex[p1];
            if (meta) {
                return 'href="/support?path=/x/' + meta.path + '"';
            } else {
                return match;
            }
        });

        edited = edited.replace(/href="\/wiki\/download\/attachments/gm, (match, p1) => {
            return 'target="ref" href="https://intrinsiccs.atlassian.net/wiki/download/attachments';
        });

        edited = edited.replace(/class="external-link"/gm, 'class="external-link" target="ref"');
        edited = edited.replace(/http:\/\/localhost:5002\//gm, "/");
        return edited;
    },

    composeContentForExternal: (body, base) => {
        let combined = (body || '') + (base || '');
        let edited = combined.replace(/href="[https://intrinsiccs.atlassian.net]*\/wiki\/spaces\/RMSD\/pages\/(\d+)\/[\w+]+"/gm, (match, p1) => {
            return 'href="https://intrinsiccs.atlassian.net/servicedesk/customer/portal/2/article/' + p1 + '" target="ref"';
        });

        return edited;
    },

    getListing: () => {
        return Object.keys(HelpIndex.content).map((path, index) => {
            let item = HelpIndex.content[path];
            return Object.assign({}, item, { path, index, title: EM.t(item.key + '.title') });
        }).sort((a, b) => {
            return (a.order || a.index) > (b.order || b.index);
        });
    },

    parsePath(path) {
        let bits = path.split('/');
        return bits.slice(1).join('/');
    }
}

export default HelpIndex;