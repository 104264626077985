/* global EM */
import React, { Component, Suspense } from 'react';
import { Input, Label, FormGroup, FormText } from 'reactstrap';
import SettingsTabs from '../components/SettingsTabs';
import EntityTable from '../components/EntityTables/EntityTable';
import EntityConfigPanel from '../components/EntityConfigPanel';
import DataOutputHelper from '../util/DataOutputHelper';
import { Workbook } from '../util/Workbook';
import Dates from '../util/Dates';
import Spinner from '../components/Spinner';
const IntegrationPanel = React.lazy(() => import('../components/Integrations/IntegrationPanel'));
const _ = require('underscore');

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            debugMode: EM.debugMode,
            activeTab: 'common',
            showingEntityConfig: false,
            showingIntegrationPanel: false
        }

        this.data = null;
        this.columns = null;

        this.onChangeParentDomain = this.onChangeParentDomain.bind(this);
        this.exportData = this.exportData.bind(this);
        this.exportAllAuditData = this.exportAllAuditData.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onChangeParentDomain(sender) {
        let newDomain = Object.assign({}, this.props.domain);
        newDomain.ParentDomainId = sender.value ? sender.value : null;
        EM.domains.update(Object.assign({}, this.props.domain), newDomain)
            .then(async () => {
                window.location.reload();
            });
    }

    exportData() {
        DataOutputHelper.exportAllData();
    }

    exportAllAuditData() {
        let domainId = EM.getActiveDomain().DomainId;
        EM.api.auditDomainEntity(domainId, '*', '*').then(data => {
            let processedData = data.map(item => {
                item._ts = Dates.fromISO(item.Timestamp);
                return item;
            }).sort((a, b) => {
                return b._ts.toMillis() - a._ts.toMillis();
            });
            new Workbook((wb) => {
                wb.addWorksheetFromJsonObjectArray('Raw Audit Data', processedData);
                wb.save('raw-audit-data-' + domainId);
            });
        }).catch(error => {
            EM.setError(error);
        });
    }

    render() {
        let self = this;
        const entity = EM.settings;
        if (EM.allLoaded(entity)) {
            this.data = entity.get();

            this.columns = [{
                dataField: 'Key',
                text: entity.columns('name'),
                width: 200,
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'Value',
                text: entity.columns('value'),
                filterOptions: {
                    text: true
                }
            }];
        }

        let domains = [];
        let domainId = this.props.domain ? this.props.domain.DomainId : null;
        if (domainId) {
            let alreadyParent = _.find(this.props.account.Domains, function (d) {
                return d.ParentDomainId === domainId;
            });
            if (!alreadyParent) {
                domains = _.reject(this.props.account.Domains, function (d) {
                    return d.ParentDomainId || d.ParentDomainId === domainId || d.DomainId === domainId;
                }).map(function (d, index) {
                    return {
                        value: d.DomainId,
                        label: d.DisplayName
                    };
                });
            }
        }

        return (
            <div key="contents" className="page container-fluid">
                <SettingsTabs {...this.props} />
                <EntityTable entity={entity} data={this.data} columns={this.columns} className={"table-overflow-container " + (this.data.length > 14 ? 'tabs-functions' : 'tabs-flow')} />

                <div className="mt-4">
                    <h5>{entity.t('utilities')}</h5>
                    {domains.length > 0 && EM.isSuperAdmin() ?
                        <div className="mt-2">
                            <FormGroup>
                                <Label>{entity.t('parentDomain')}:</Label>
                                <Input key="ctrl" type="select" onChange={(event) => { this.onChangeParentDomain(event.target); }} value={domainId ? self.props.domain.ParentDomainId || "" : ""}>
                                    <option key="parentDomainEmpty"></option>
                                    {domains.map((option, index) => {
                                        return <option value={option.value} key={"parentDomain" + index}>{option.label}</option>
                                    })}
                                </Input>
                                <FormText color="muted">{entity.t('parentDomainWarning')}</FormText>
                            </FormGroup>
                        </div>
                        : null}
                    <div className="mt-4 btn-group-sep">
                        <button className="btn btn-outline-warning" onClick={() => {
                            this.setState({ debugMode: !EM.debugMode });
                            EM.debugMode = !EM.debugMode;
                            window.location.reload();
                        }}>{this.state.debugMode ? entity.t('disable') : entity.t('enable')} {entity.t('debugMode')}</button>
                        {EM.isDomainAdmin() ?
                            <button className="btn btn-outline-secondary" onClick={() => {
                                this.exportData();
                            }}>{entity.t('export')}</button>
                            : null}
                        {EM.isSuperAdmin() ?
                            <button className="btn btn-outline-info beta" onClick={() => {
                                this.exportAllAuditData();
                            }}>Export Raw Audit Trail</button>
                            : null}
                        {EM.isSuperAdmin() ?
                            <button className="btn btn-outline-secondary float-right ml-2" onClick={() => {
                                EM.loadEntities([EM.configurationTypeLookup, EM.configurations]).then(() => {
                                    window.setTimeout(() => {
                                        this.setState({ showingEntityConfig: true });
                                    }, 0);
                                });
                            }}>Configure Entities</button>
                            : null}
                        
                        {(EM.isDomainAdmin() || EM.isSuperAdmin()) && (
                            (EM.getSetting('IntegrationEndpoint') || EM.getSetting('IntegrationEndpoint.V2')) && (
                                <button
                                    className="btn btn-outline-secondary float-right"
                                    onClick={() => {
                                        this.setState({ showingIntegrationPanel: true });
                                    }}
                                >
                                    Run an Integration
                                </button>
                            )
                        )}
                    </div>
                </div>

                {this.state.showingEntityConfig ?
                    <EntityConfigPanel
                        isOpen={!!this.state.showingEntityConfig}
                        onClose={() => {
                            this.setState({ showingEntityConfig: false });
                        }}
                    />
                    : null}

                {this.state.showingIntegrationPanel ?
                    <Suspense fallback={<Spinner text="Loading..." classes="m-4" />}>
                        <IntegrationPanel isOpen={this.state.showingIntegrationPanel} onClose={() => {
                            this.setState({ showingIntegrationPanel: false });
                        }} />
                    </Suspense>
                    : null}
            </div>
        );
    }
}

export default Settings;
