/* global EM */
import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import MonthPicker from '../MonthPicker';
import Dates from '../../util/Dates';
import Select from 'react-select';
import StaffingWorkItemPreferences from '../../entities/preferences/StaffingWorkItemPreferences';
import FilterSelection from '../FilterSelection';
import CannedDateRangeSelector from '../CannedDateRangeSelector';
import OptionSwitcher from '../OptionSwitcher';

export default class StaffingWorkItemToolsPanel extends Component {
    constructor(props) {
        super(props);

        this.defaultPrefs =  new StaffingWorkItemPreferences('default');
        this.state = {
            currentPreferences: this.defaultPrefs
        }

        this.prefs = this.defaultPrefs.group;
        this.onChange = this.onChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.addResetPreferences = this.addResetPreferences.bind(this);
        this.setPreferences = this.setPreferences.bind(this);
        this.deletePreferences = this.deletePreferences.bind(this);
    }

    addResetPreferences(name, startFromCurrent) {
        let self = this;
        let newSet = startFromCurrent ? self.state.currentPreferences.clone().inst : {};
        self.prefs(name, newSet);
        self.setPreferences(name);       
    }

    deletePreferences(name){
        let self = this;
        self.prefs(name, null, true);
        self.setPreferences('default');
    }

    setPreferences(name, suppressNotification) {
        let self = this;
        let newPrefs = new StaffingWorkItemPreferences(name);
        self.setState({ currentPreferences: newPrefs });
        if (!suppressNotification) self.props.onChange(newPrefs);
    }    

    onChange() {
        let self = this;
        let newPrefs = self.state.currentPreferences.clone();  
        self.setState({ currentPreferences: newPrefs }, () => {
            window.setTimeout(() => {
                self.props.onChange(newPrefs);
            }, 100);
        });        
    }

    onTabChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        let preferences = this.state.currentPreferences;

        let optSets = [{ label: 'Default', value: 'default' }, ...this.prefs().map(key => {
            if (key === 'default') return null;
            return { label: key, value: key }
        }).filter((el) => el != null)];
        let selectedOptSet = optSets.find(os => os.value === preferences.name);
            
        let beginDate = preferences.begin ? Dates.fromStr(preferences.begin, Dates.DATE_PREF) : null;        
        let endDate = preferences.end ? Dates.fromStr(preferences.end, Dates.DATE_PREF) : null;
        
        let groupings = [{ label: 'None', value: null }];
        if (this.props.filterValues){
            Array.prototype.push.apply(groupings, this.props.filterValues.map((filter, index) => {
                return { label: filter.label, value: filter.name };
            }).filter((filter) => {
                return filter.value !== 'workitem' && filter.value !== 'activity';
            }));
        }

        let selectedGrouping = groupings.find((grp) => {
            return grp.value === preferences.grouping;
        });

        let roles = EM.roles.asOptionList();
        let selectedFocusRoles = roles.filter((role) => {
            if (!preferences.focusRoles)return null;
            return preferences.focusRoles.indexOf(role.value) > -1;
        });
        
        let workItemInFilter=this.props?.filterValues?.find(x => x.name === "workitem")? true : false;
        let activityInFilter=this.props?.filterValues?.find(x => x.name === "activity")? true : false;
        return (
            <div className={"tools-panel " + this.props.className}>
                <div className="tools-panel-header">
                    <h5>{EM.t('staffing.tools.title')}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.onFilterClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="tools-panel-content">                                      
                    <OptionSwitcher 
                        options={optSets}
                        value={selectedOptSet}
                        addResetPreferences={this.addResetPreferences} 
                        deletePreferences={this.deletePreferences}
                        onChange={this.setPreferences}
                    />                                                     
                    <FormGroup>
                        <label>{EM.t('staffing.tools.dateRange')}:</label><br />
                        <div className="month-range">
                            <MonthPicker
                                placeholder={EM.t('staffing.tools.from')}
                                date={beginDate}
                                onChange={(date) => {
                                    preferences.begin = date.toFormat(Dates.DATE_PREF);
                                    this.onChange();
                                }}
                            />
                            <MonthPicker
                                placeholder={EM.t('staffing.tools.to')}
                                date={endDate}
                                onChange={(date) => {
                                    preferences.end = date.toFormat(Dates.DATE_PREF);
                                    this.onChange();
                                }}
                            />
                            <CannedDateRangeSelector 
                                onSelect={(begin, end) => {
                                    preferences.begin = begin ? begin.toFormat(Dates.DATE_PREF) : null;
                                    preferences.end = end ? end.toFormat(Dates.DATE_PREF) : null;
                                    this.onChange();
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <label>{EM.t('staffing.tools.grouping')}:</label><br />
                        <Select
                            className="single-select"
                            classNamePrefix="single-select"
                            isSearchable={true}
                            options={groupings}
                            value={selectedGrouping}
                            placeholder={EM.t('util.filtering.placeholderDefault')}
                            onChange={(selectedItem) => {
                                preferences.grouping = selectedItem.value;
                                this.onChange();
                            }}
                        /> 
                    </FormGroup>
                    <FormGroup>
                        <label>{EM.t('staffing.tools.focusRoles')}:</label><br />
                        <Select
                            className="multi-select"
                            classNamePrefix="multi-select"
                            isSearchable={true}
                            isMulti={true}
                            options={roles}
                            value={selectedFocusRoles}
                            placeholder={EM.t('util.filtering.placeholder')}
                            onChange={(values) => {
                                preferences.focusRoles = values ? values.map(item => { return item.value }) : [];
                                this.onChange();
                            }}
                        /> 
                    </FormGroup>                    
                    <hr/>
                    {this.props.filterValues ?
                        <div>
                            {this.props.filterValues.map((filter, index) => {
                                return <>
                                {(index == 1 && workItemInFilter && !activityInFilter) || (index == 1 && !workItemInFilter && activityInFilter)? <hr/>:''}
                                <FilterSelection
                                    selected={preferences.get(filter.name)}
                                    key={filter.name}
                                    options={filter.values}
                                    label={filter.label}
                                    onChange={(values) => { 
                                        preferences.set(filter.name, values);
                                        this.onChange();
                                    }} />
                                    </>
                            })}
                        </div>
                    : null }
                    <div className="inputs-inline">                                
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={preferences.onlyActive} onChange={(event) => {
                                    preferences.onlyActive = event.target.checked;
                                    this.onChange();
                                }}/>
                                {EM.t('staffing.tools.onlyActive')}
                            </Label>
                        </FormGroup> 
                    </div>
                </div>
            </div>
        );
    }
}
